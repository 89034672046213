/*--------------------------------------------------
Slider
--------------------------------------------------*/
.home-slider {
    position: relative;
    overflow: hidden;

    * {
        outline: none;
    }
    &--wrapper {
        &__inner {
            min-height: 100vh;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: "";
                background: rgba(0, 0, 0, 0.7);
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 0;
            }

            .container {
                position: relative;
                z-index: 1;
                color: #fff;
                text-align: center;

                h3 {
                    font: 400 24px/1 $second-font;
                    margin: 0 0 50px;
                    text-transform: capitalize;
                }
                h1 {
                    font: 600 72px/1 $main-font;
                    margin: 0 0;
                    text-transform: uppercase;
                }
                p {
                    font: 400 14px/24px $main-font;
                    margin: 0 auto 70px auto;
                    max-width: 600px;
                    color: $font-grey;
                }
            }
        }
    }
    &--anchor {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 125px;
        text-align: center;
        display: flex;
        align-items: flex-end;

        span {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $blue;
            background: $grey;
            height: 60px;
            width: 60px;
            border-radius: 50px;
            margin: 0 auto;
            cursor: pointer;
            position: relative;
            z-index: 1;
            font-size: 22px;
        }
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50px;
            width: 50%;
            height: 500px;
            background: #fff;
            transform: skew(0deg, 8deg);
            z-index: 0;
        }
        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 50px;
            width: 50%;
            height: 500px;
            background: #fff;
            transform: skew(0deg, -8deg);
            z-index: 0;
        }
    }
    &--nav {
        position: absolute;
        top: 40vh;
        width: 100%;

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 45px;
            width: 45px;
            background: #6f6863;
            color: #3c3530;
            cursor: pointer;
            border-radius: 4px;
            font-size: 35px;
            position: absolute;
            left: 50px;
            top: 0;
            transition: all 0.3s ease;

            &.next {
                right: 50px;
                left: auto;
            }
            &:hover {
                background: $blue;
                color: $white;
            }
        }
    }
}



@media screen and (max-width: 991px) {
    .home-slider {
        &--wrapper {
            &__inner {
                .container {
                    h3 {
                        font-size: 22px;
                    }
                    h1 {
                        font-size: 55px;
                        margin: 0 auto;
                        width: 60%;
                    }
                    .slider-buttons {
                        .button {
                            display: block;
                            width: 100%;
                            margin: 0 0 10px;
                        }
                    }
                }
            }
        }
        &--anchor {
            span {
                top: -20px;
            }
        }
    }
}

@media screen and (max-width: 575px) {
    .home-slider {
        &--wrapper {
            &__inner {
                padding-top: 40px;

                .container {
                    h3 {
                        display: none;
                    }
                    h1 {
                        font-size: 40px;
                        width: 100%;
                    }
                }
            }
        }
        &--anchor {
            display: none;
        }
        &--nav {
            display: none;
        }
    }
}
