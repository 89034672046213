//--------------------------------------------------
//Variables
//--------------------------------------------------

//colors
$white: #ffffff;
$blue: #3c88e7;
$grey: #f4f5f9;
$font-grey: #cbc6c0;
$border-grey: #e5e5e5;
$text-grey: #999999;
$main-grey: #6a6a6a;
$dark-grey: #202020;
$black: #000000;

//animations
$expo: cubic-bezier(1.000, 0.000, 0.000, 1.000); 
$smooth: cubic-bezier(.49, .06, .21, .98);

//fonts
$main-font: 'Open Sans';
$second-font: 'Source Sans Pro';

//directory

$img-dir: '../assets/img/';
